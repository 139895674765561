import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.blog-feed').length > 0 ) {
		const settings = {
			infinite: true,
			dots: true,
			arrows: false,
			adaptiveHeight: true,
			autoplay: true,
			autoplaySpeed: 7000,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			responsive: [
				{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
				},
				{
				breakpoint: 1024,
				settings: "unslick"
				}
			]
		};

		var resourcesSlider = {
			init: function(){
				$('.blog-feed').each(function(){
					const slider = $(this).find('.post-card__wrapper');
					const sl =  slider.slick(settings);

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( $(window).width() < 1024 &&  !sl.hasClass('slick-initialized')) {
							resourcesSlider.init();
						  }	
						}, 500, 'resources');
					});
			 	});
				
			}
		};

		$(function() {
			resourcesSlider.init();
		});


	}
})(jQuery);
import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	var mobileMenu = {
        init: function(){
			if ($(window).width() < 768) {
				$('.site-footer__nav-title:not(.company)').each(function() {
					$(this).on('click', function(e) {
						e.preventDefault();
						$(this).toggleClass('active');
						$(this).next('.site-footer__menu-wrapper').toggleClass('active');
					});
				});
			}
		}
	};

    $(function() {
        mobileMenu.init();
    });

    $(window).on("resize orientationchange", function () {
        waitForFinalEvent(function(){
            mobileMenu.init();
        }, 500, 'mobile-menu');
    });	

})(jQuery);
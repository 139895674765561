(function($) {
  if ( $('.testimonials').length > 0 ) {
      $('.testimonials').each(function(){
          var $slider = $(this).find('.testimonials__item-wrapper');

          const settings = {
              infinite: true,
              dots: true,
              arrows: false,
              adaptiveHeight: false,
              autoplay: true,
              autoplaySpeed: 7000,
              slidesToShow: 1,
              slidesToScroll: 1,
          };

          $slider.slick(settings);
      });
  }

})(jQuery);
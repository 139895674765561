(function($) {
    if ( $('.form-block').length > 0 ) {
        $('.form-block').each(function(){
            var tab_header = $(this).find('.form-block__form-title-link');
            var tab_content = $(this).find('.form-block__form');
            tab_header.each(function(){
                $(this).on('click',function(e){
                    e.preventDefault();
                    tab_header.removeClass('active');
                    $(this).addClass('active');

                    var target = $(this).attr('href');
                    tab_content.hide();
                    $(target).show();
                });
            });
        });
    }
  
  })(jQuery);
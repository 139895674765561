(function($) {
    $('.accordion__wrapper details').each(function(){
        $(this).on('click',function(){
            $this_image = $(this).attr('data-accordion-image');
            $target_image = $(this).parents('.wrapper ').find('.content-with-image__image');
            // console.log($this_image);
            $target_image.attr('src', $this_image);
        });
    });
    
})(jQuery);
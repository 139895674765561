(function($) {
    $('.faq').each(function(){
        $(this).find('.faq__item').each(function(){
            $(this).find('.faq__item-title').on('click',function(){
                $(this).toggleClass('open');
                $(this).next('.faq__item-content').slideToggle('medium');
            })
        });
    });
    
})(jQuery);
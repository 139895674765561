(function ($) {
  if ($('.stats').length > 0) {
    var winHeight = $(window).height(),
      firEvent = false,
      objectPosTop = $('.stats').offset().top,
      elementViewInBottom = objectPosTop - winHeight;

    $(window).on('scroll', function () {
      var currentPosition = $(document).scrollTop();
      //when element position starting in viewport
      if (currentPosition > elementViewInBottom && firEvent === false) {
        firEvent = true;
        let valueDisplays = document.querySelectorAll('.num');
        let interval = 3000;

        valueDisplays.forEach((valueDisplays) => {
          let startValue = 0;
          let endValue = parseInt(valueDisplays.getAttribute('data-val'));
          let duration = Math.floor(interval / endValue);
          let number = valueDisplays.querySelector(".number");
          let counter = setInterval(function () {
            startValue += 1;
            number.textContent = startValue;
            if (startValue == endValue) {
              clearInterval(counter);
            }
          }, duration);
        });
      }
    });
  }
})(jQuery);

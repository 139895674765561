(function($) {
    if ( $('.solutions-carousel').length > 0 ) {
        $('.solutions-carousel').each(function(){
            var $slider = $(this).find('.solutions-carousel__box-wrapper');
            var $arrow_wrapper = $(this).find('.solutions-carousel__arrow-wrapper');

            const settings = {
                infinite: true,
                dots: false,
                arrows: true,
                appendArrows: $arrow_wrapper,
                adaptiveHeight: false,
                variableWidth: true,
                autoplay: true,
                autoplaySpeed: 7000,
                slidesToShow: 2,
                slidesToScroll: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 1028,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }                        
                    }
                ]
            };

            $slider.slick(settings);
        });
    }

})(jQuery);
jQuery(document).ready(function ($) {
  $toTop = $('div#to_top');
  $toTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $($toTop).fadeIn();
    } else {
      $($toTop).fadeOut();
    }
  });

  //Click event to scroll to top
  $($toTop).click(function () {
    console.log('clicked to top');
    $('html, body').animate({ scrollTop: 0 }, 800);
    return false;
  });
}); // jquery noConflict
